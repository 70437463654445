import store from '@/store';
import {
    mdiAccountCheckOutline,
    mdiAccountOutline,
    mdiAccountPlusOutline,
    mdiAccountRemoveOutline,
    mdiCogOutline,
    mdiDatabaseOutline,
    mdiDnsOutline,
    mdiPencilOutline,
} from '@mdi/js';
import { ref, watch } from '@vue/composition-api';
import _ from 'lodash';
import { i18n } from '@/plugins/i18n';

export default function useEntitiesList() {
    const linkListTable = ref([]);

    const tableColumns = [
        { text: i18n.t('Title'), value: 'title', class: 'text-uppercase', width: '12rem' },
        { text: i18n.t('Description'), value: 'description', class: 'text-uppercase', width: '30rem' },
        { text: i18n.t('Link'), value: 'link', class: 'text-uppercase' },
        { text: i18n.t('Category'), value: 'category', class: 'text-uppercase' },
        {
            text: '',
            value: 'actions',
            width: '5%',
        },
    ];

    const searchQuery = ref('');
    const roleFilter = ref(null);
    const planFilter = ref(null);
    const statusFilter = ref(null);
    const totalLinkListTable = ref(0);
    const loading = ref(false);
    const options = ref({
        sortBy: ['id'],
        sortDesc: [true],
    });
    const linkTotalLocal = ref([]);
    const selectedRows = ref([]);
    const oldSearch = ref('');
    const oldTitleFilter = ref(' ');
    const oldDescriptionFilter = ref(' ');
    const oldCategoryFilter = ref(' ');

    // fetch data
    const fetchLinkList = () => {
        let verifyQuery = store.getters['app-link/getLinkQuery'];
        let isDeleteLink = store.getters['app-link/getIsDeleteLink'];
        store
            .dispatch('app-link/fetchLinkList', {})
            .then((response) => {
                if (isDeleteLink) {
                    linkListTable.value = [];
                    linkListTable.value.push(...response.data.data);
                    store.commit('app-link/setIsDeleteLink', false);
                } else if (
                    oldSearch.value[0] == verifyQuery.filters.$or[0].title.$containsi &&
                    oldSearch.value[1] == verifyQuery.filters.$or[1].description.$containsi &&
                    oldCategoryFilter.value === verifyQuery.filters.category.$containsi
                ) {
                    linkListTable.value = [];
                    linkListTable.value.push(...response.data.data);
                } else {
                    oldSearch.value[0] == verifyQuery.filters.$or[0].title.$containsi;
                    oldSearch.value[1] == verifyQuery.filters.$or[1].description.$containsi;
                    oldCategoryFilter.value = verifyQuery.filters.category.$containsi;
                    linkListTable.value = [];
                    linkListTable.value.push(...response.data.data);
                    linkListTable.value.forEach((element) => {
                        if (element.category == 'Guides') {
                            element.category = 'Guias';
                        } else if (element.category == 'Associations') {
                            element.category = 'Associações';
                        } else if (element.category == 'Forms') {
                            element.category = 'Formulários';
                        } else if (element.category == 'Events') {
                            element.category = 'Eventos';
                        }
                    });
                }
                totalLinkListTable.value = response.data.meta.pagination.total;
                // linkTotalLocal.value = linkTotal;

                // remove loading state
                loading.value = false;
            })
            .catch((error) => {
                throw error;
            });
    };

    watch([searchQuery, roleFilter, planFilter, statusFilter, options], () => {
        loading.value = true;
        selectedRows.value = [];
        fetchLinkList();
    });

    const resolveLinkPictureMax = (cover) => {
        if (_.get(cover, 'formats.large.url')) return _.get(cover, 'formats.large.url');

        if (_.get(cover, 'url')) return _.get(cover, 'url');
        return null;
    };

    const resolveLinkRoleVariant = (role) => {
        if (role === 'subscriber') return 'primary';
        if (role === 'author') return 'warning';
        if (role === 'maintainer') return 'success';
        if (role === 'editor') return 'info';
        if (role === 'admin') return 'error';

        return 'primary';
    };

    const resolveLinkRoleIcon = (role) => {
        if (role === 'subscriber') return mdiAccountOutline;
        if (role === 'author') return mdiCogOutline;
        if (role === 'maintainer') return mdiDatabaseOutline;
        if (role === 'editor') return mdiPencilOutline;
        if (role === 'admin') return mdiDnsOutline;

        return mdiAccountOutline;
    };

    const resolveEntitiestatusVariant = (status) => {
        if (status === 'pending') return 'warning';
        if (status === 'active') return 'success';
        if (status === 'inactive') return 'secondary';

        return 'primary';
    };

    const resolveLinkTotalIcon = (total) => {
        if (total === 'Total Entities') return { icon: mdiAccountOutline, color: 'primary' };
        if (total === 'Paid Entities') return { icon: mdiAccountPlusOutline, color: 'error' };
        if (total === 'Active Entities') return { icon: mdiAccountCheckOutline, color: 'success' };
        if (total === 'Pending Entities') return { icon: mdiAccountRemoveOutline, color: 'warning' };

        return { icon: mdiAccountOutline, color: 'primary' };
    };

    const limitLinkText = (entities) => {
        if (entities.length > 1) {
            return ' + ' + (entities.length - 1);
        }
    };

    return {
        linkListTable,
        tableColumns,
        searchQuery,
        roleFilter,
        planFilter,
        statusFilter,
        totalLinkListTable,
        loading,
        options,
        linkTotalLocal,
        selectedRows,
        fetchLinkList,
        resolveLinkPictureMax,
        resolveLinkRoleVariant,
        resolveLinkRoleIcon,
        resolveEntitiestatusVariant,
        limitLinkText,
    };
}
